<template>
    <settings-wrapper>
        <template v-slot:child>
            <div class="w-90 center flex flex-wrap justify-between">
                <div class="w-40-l w-100 mt4 box-border pa2">
                    <div class="flex items-center justify-between pa3 box-border-bottom" style=" background-color:#E9EDFC; ">
                        <div class="flex">
                            <img src="imgs/BA.svg" alt="" />
                            <div class="pl3">
                                <div class="pb1 font-w2">Adeola young</div>
                                <div class="lh-copy pb1">Dangote sent you 200 million dollars</div>
                                <div class="o-80">Today</div>
                            </div>
                        </div>
                        <div class="pl4">
                            <img src="imgs/email.png" style="width: 30px;" alt="" />
                        </div>
                    </div>
                    <!-- mobile display -->
                    <div class="dn-l box-border-bottom">
                        <div class="flex pa4">
                            <img src="imgs/BA.svg" alt="" />
                            <div class="pl3">
                                <div class="pb1 font-w2">Adeola young</div>
                                <div class="o-80">Today</div>
                            </div>
                        </div>
                        <div class="ph4 lh-copy">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit deserunt maiores vel officia ut,
                                neque nulla, expedita ipsa consectetur numquam maxime nostrum distinctio aut eaque fugiat
                                aspernatur cumque doloremque nihil!
                            </p>
                            <p>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta laboriosam velit voluptates eos
                                facilis, itaque delectus, ab recusandae earum alias nemo nesciunt iure? Exercitationem, laboriosam
                                quos ipsam consectetur quasi ea!
                            </p>
                        </div>
                        <div class="pa4">
                            <button class="btn2">Archive</button>
                            <button class="ml3">Delete</button>
                        </div>
                    </div>
                    <div class="flex items-center justify-between pa3 box-border-bottom">
                        <div class="flex">
                            <img src="imgs/BA.svg" alt="" />
                            <div class="pl3">
                                <div class="pb1 font-w2">Adeola young</div>
                                <div class="lh-copy pb1">Dangote sent you 200 million dollars</div>
                                <div class="o-80">Today</div>
                            </div>
                        </div>
                        <div class="pl4">
                            <img src="imgs/bell.png" style="width: 30px;" alt="" />
                        </div>
                    </div>
                    <div class="flex items-center justify-between pa3 box-border-bottom">
                        <div class="flex">
                            <img src="imgs/BA.svg" alt="" />
                            <div class="pl3">
                                <div class="pb1 font-w2">Adeola young</div>
                                <div class="lh-copy pb1">Dangote sent you 200 million dollars</div>
                                <div class="o-80">Today</div>
                            </div>
                        </div>
                        <div class="pl4">
                            <img src="imgs/email.png" style="width: 30px;" alt="" />
                        </div>
                    </div>
                    <div class="flex items-center justify-between pa3 box-border-bottom">
                        <div class="flex">
                            <img src="imgs/BA.svg" alt="" />
                            <div class="pl3">
                                <div class="pb1 font-w2">Adeola young</div>
                                <div class="lh-copy pb1">Dangote sent you 200 million dollars</div>
                                <div class="o-80">Today</div>
                            </div>
                        </div>
                        <div class="pl4">
                            <img src="imgs/email.png" style="width: 30px;" alt="" />
                        </div>
                    </div>
                    <div class="flex items-center justify-between pa3 box-border-bottom">
                        <div class="flex">
                            <img src="imgs/BA.svg" alt="" />
                            <div class="pl3">
                                <div class="pb1 font-w2">Adeola young</div>
                                <div class="lh-copy pb1">Dangote sent you 200 million dollars</div>
                                <div class="o-80">Today</div>
                            </div>
                        </div>
                        <div class="pl4">
                            <img src="imgs/email.png" style="width: 30px;" alt="" />
                        </div>
                    </div>
                </div>

                <div class="w-60-l w-100 box-border pa2 mt4 dn db-l">
                    <div>
                        <div class="flex pa4">
                            <img src="imgs/BA.svg" alt="" />
                            <div class="pl3">
                                <div class="pb1 font-w2">Adeola young</div>
                                <div class="o-80">Today</div>
                            </div>
                        </div>
                        <div class="ph4 lh-copy">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit deserunt maiores vel officia ut,
                                neque nulla, expedita ipsa consectetur numquam maxime nostrum distinctio aut eaque fugiat
                                aspernatur cumque doloremque nihil!
                            </p>
                            <p>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta laboriosam velit voluptates eos
                                facilis, itaque delectus, ab recusandae earum alias nemo nesciunt iure? Exercitationem, laboriosam
                                quos ipsam consectetur quasi ea!
                            </p>
                        </div>
                        <div class="pa4">
                            <button class="btn2">Archive</button>
                            <button class="ml3">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
// import { computed, onMounted, reactive, ref, watch } from 'vue'
// import { useStore } from 'vuex'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'

export default {
    name: 'Notification',
    components: { SettingsWrapper }
}
</script>

<style scoped></style>
